import { DestroyRef, ElementRef, Injector, Renderer2 } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AbpMultiTenancyService, FeatureCheckerService, LocalizationService, MessageService, NotifyService, PermissionCheckerService, SettingService } from 'abp-ng2-module';

import { AppSessionService } from '@shared/session/app-session.service';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { PermissionConsts } from '@shared/permission-consts';
import { MessageService as PrimeMessageService, PrimeNGConfig } from 'primeng/api';

/**
 * @class AppComponentBase
 * @classdesc Abstract component class for all other application components. Implements various utility services and methods.
 */
export abstract class AppComponentBase {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    primeMessage: PrimeMessageService;
    primeConfig: PrimeNGConfig;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    elementRef: ElementRef;
    titlePage: Title;
    renderer2: Renderer2;
    location: Location;
    destroyRef: DestroyRef;

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.primeMessage = injector.get(PrimeMessageService);
        this.primeConfig = injector.get(PrimeNGConfig);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.elementRef = injector.get(ElementRef);
        this.titlePage = injector.get(Title);
        this.renderer2 = injector.get(Renderer2);
        this.location = injector.get(Location);
        this.destroyRef = injector.get(DestroyRef);
    }

    /**
     * Localize a given key string with its value on Localization Manager
     * @param key
     * @param args
     */
    l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    /**
     * Checks if the current user has access to a given permission
     * @param permissionName
     */
    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    getUserSession() {
        return this.appSession.user;
    }

    /**
     * Permissions enum
     */
    get permissionsConst() {
        return PermissionConsts;
    }

    setTitle(t: string) {
        this.titlePage.setTitle(this.l(t));
    }

    startLoading() {
        abp.ui.setBusy(document.body);
        this.renderer2.addClass(document.body, 'overflow-hidden');
    }

    stopLoading() {
        abp.ui.clearBusy(document.body);
        this.renderer2.removeClass(document.body, 'overflow-hidden');
    }

    goBack() {
        this.location.back();
    }

    /**
     * Shows success toast with given message content
     * @param msg
     */
    successMessage(msg: string) {
        this.primeMessage.add({ severity: 'success', summary: this.l('Success'), detail: msg });
    }

    /**
     * Shows warning toast with given message content
     * @param msg
     */
    warningMessage(msg: string) {
        this.primeMessage.add({ severity: 'warn', summary: this.l('Warning'), detail: msg });
    }

    /**
     * Shows error toast with given message content
     * @param msg
     */
    errorMessage(msg: string) {
        this.primeMessage.add({ severity: 'error', summary: this.l('Error'), detail: msg });
    }

    isInRole(role: string) {
        //TODO: inserire gestione dei roles di appSession
        //return this.appSession.user.roleNames.some((r) => r === role);
    }
}
