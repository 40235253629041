import { ClientiComponent } from './../../app/clienti/clienti.component';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';
import { LoaderInterceptor } from '@shared/interceptors/loader.interceptor';
import * as DownloadDocumentiServiceProxies from './documenti-download';
@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.RidersServiceProxy,
        ApiServiceProxies.MezziDiConsegnaRiderServiceProxy,
        ApiServiceProxies.ProdottiServiceProxy,
        ApiServiceProxies.CategorieProdottoServiceProxy,
        ApiServiceProxies.UnitaDiVenditaTipiServiceProxy,
        ApiServiceProxies.OrdiniServiceProxy,
        ApiServiceProxies.OrdineProdottiServiceProxy,
        ApiServiceProxies.DocumentiServiceProxy,
        ApiServiceProxies.ConsegnaLuoghiServiceProxy,
        ApiServiceProxies.TipiPagamentoServiceProxy,
        ApiServiceProxies.StatiDiPagamentoOrdineServiceProxy,
        ApiServiceProxies.FasceOrarieServiceProxy,
        ApiServiceProxies.StatiDiAvanzamentoOrdineServiceProxy,
        ApiServiceProxies.SessioniDiVenditaServiceProxy,
        ApiServiceProxies.StatiSessioneDiVenditaServiceProxy,
        ApiServiceProxies.PreOrdineProdottiServiceProxy,
        DownloadDocumentiServiceProxies.DocumentiDownloadServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
